<template>
  <v-chip class="pr-0" color="orange" @click="CopyToBuffer(value)">
    <span>{{ title }} </span>
    <v-chip color="green" class="ml-2 chip-value">
      {{ value }}

      <v-chip v-if="value2 && !title2" color="green" class="ml-1 chip-value">
        {{ value2 }}
      </v-chip>
    </v-chip>
    <v-chip v-if="value2 && title2" color="orange" class="ml-1 chip-value text-h8">
      {{ value2 }}
    </v-chip>
  </v-chip>
</template>

<script>
import ClipboardData from "@/mixins/ClipboardData";

export default {
  name: "InfoChip",
  mixins: [ClipboardData],
  props: {
    title: {
      type: String,
      require: true,
    },
    title2: {
      type: String,
      require: false,
    },
    value: {
      type: String,
      require: true,
    },
    value2: {
      type: String,
      require: false,
    }
  },
  data() {
    return {
      isCopiedToBuffer: false,
      bufferText: "",
    };
  },
};
</script>

<style></style>
